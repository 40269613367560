.sidebar {
  display: block;
  width: 18.59vw;
  height: 100vh;
  padding: calc(130px + 20px) 0;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #173761; /* Old browsers */
  background: -moz-linear-gradient(top, #173761 0%, #26a2a7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #173761 0%, #26a2a7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #173761 0%, #26a2a7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  &_logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.59vw;
    height: 130px;
    padding: 0;
    padding: 0 1.5vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 0;
    line-height: 0;
    .logo {
      font-size: 0;
      line-height: 0;
      width: 100%;
      height: 94px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sidebar_menu {
    ul {
      padding: 0;
      margin: 0;
    }
  }

  .app-contentnode {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid rgba(255,255,255,0.2);

    &:only-child {
      border-top: 1px solid rgba(255,255,255,0.2);
    }
  }
}
