.app-timepicker {
  position: fixed;
  .loader {

  }
}
.lm--button-picker {
  &.timepicker {
    .app-icon {
      &.loader {
        right: auto;
        left: 1rem;
      }
    }
  }
}
