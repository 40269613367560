.signup{
  position: relative;
  z-index: 1000;
  .page-title{
    padding: 1em 0;
    background-color: transparent;

    .lm--timeline{
      width: 100%;
      padding-left: 0;
      li{
        flex: 0 1 200px;
        &:before {
          width: 20px;
          height: 20px;
          background-size: 10px 10px;
        }
        &:after {
          height: 2px;
          top: 9px;
        }
        &.is-active {
          &:before {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .h2{
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .page-content{
    // background-color: #ffffff;
    background-color:transparent;
    padding: 1em 0 9em;
    padding-bottom: 0;

    .signup-content {
      width: 100%;
      margin: rem(16) auto 0 auto;
      padding-bottom: 5rem;

      header {
        margin-bottom: rem(14);

        .subtitle {
          font-size: 16px;
        }
      }

      .prev-info {
        font-size: 16px;
      }

      .plan-name{
        font-size: 16px;
      }

      .form {
        &-container {
          background: #ffffff;
          padding: 0px 110px 0px 34px;

          &-title {
            display: inline-block;
            margin-right: rem(8);
          }

          &-subtitle {
            font-weight: bold;
            padding: 20px 0 10px 0;
            display: block;
          }

          .upper { 
            text-transform: uppercase; 
          }

          .split-input {
            width: 50%;
            display: inline-block;
            text-align: center;
          }

          .split-input.first {
            border-radius: 5px 0px 0px 5px;
          }

          .split-input.second {
            border-radius: 0px 5px 5px 0px;
          }

          .mobile_prefix {
            width: 70px;
            display: inline-block;
            text-align: center;
            border-radius: 5px 0px 0px 5px;
            border-right: 0px;
          }

          .number {
            width: calc(100% - 70px);
            display: inline-block;
            border-radius: 0px 5px 5px 0px;
          }

          .img-placeholder {
            position: relative;
            overflow: hidden;
            display: inline-flex;
            width: 80px;
            padding-right: 10px;
            vertical-align: top;
          }

          .btn {
            border: 1px dashed #e0e0e0;
            color: #e0e0e0;
            background-color: white;
            padding: 8px 20px;
            border-radius: 5px;
          }

          .btn-selected {
            border: 1px dashed #00b0b2;
            color: #00b0b2;
            background-color: white;
            border-radius: 5px;
            width: 75px;
            height: 50px;
          }

          .img-placeholder input[type=file] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          .hidden {
            display: none;
          }

          .thumbnail {
            padding: 10px 10px 10px 0;
            display: inline-block;
      
            img {
              height: 65px;
              border: 1px solid #e0e0e0;
              border-radius: 5px;
            }
          }

          //override atlas style
          .lm--formItem-label{
            width: 199px;
          }

          .lm--formItem-control{
            width: calc(100% - 199px);
          }

          .u-grid{
            margin-bottom: 10px;
            .label{
              text-align: right;
            }
          }

          .u-grid.section{
            margin-bottom: 20px;
          }

          //mobile size
          @media only screen and (max-width: 766px){
            .lm--switch{
              width: calc(50% - 0.5rem);
            }

            .lm--formItem.lm--formItem--inline{
              .lm--formItem-label{
                text-align: left;
              }
              .lm--formItem-control{
                width: 100%;
              }
            }

            .u-grid{
              .label{
                text-align: left;
              }
            }
          }
        
        }
      }

      @media only screen and (max-width: 766px){
        .form {
          &-container {
            padding: 0px 34px 0px 34px;
          }
        }
      }
      
      .thank-you-screen {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 150px;
  
        .thank-you-image {
          align-items: center;
          display: flex;
          justify-content: center;
          padding-bottom: 40px;
  
          img {
            height: 100px;
          }
        }
  
        .thank-you-desc {
          text-align: center;
          width: 250px;
          h3 {
            padding-bottom: 10px;
          }
          p {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
