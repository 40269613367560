.field-otp {
  &__input {
    min-width: 45px !important;
    min-height: 44px;
    flex-basis: 40px;
    padding: 0.5em !important;
  }

  &__cont {
    & > div {
      width: 45px;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    justify-content: space-between;
    align-items: center;
  }

  &__request {
    margin: 0;
    &.is-timeout {
      &:after {
        content: attr(data-after);
        display: inline-block;
        position: absolute;
        left: 100%;
        margin-left: 1rem;
        width: 250px;
        font-size: 0.8rem;
        text-align: left;
      }
    }
  }

  &.is-verified {
    .field-otp__input {
      border-color: #00b0b2;
    }

    .control-container {
      .field-otp__wrap {
        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-left: 1rem;
          position: absolute;
          top: 50%;
          left: 100%;
          width: 9px;
          height: 15px;
          transform: scale(1.2) rotate(45deg) translate3d(-50%, -50%, 0);
          border-style: solid;
          border-width: 0 2px 2px 0;
          border-color: #00b0b2;
          transform-origin: 50% 100%;
        }
      }
    }
  }

  .control {
    padding-left: 0.5rem;

    a {
      display: inline-block;
    }

    .control-prefield {
      padding-bottom: 0.5rem;
      small {
        a {
          display: inline;
          span {
            display: inline !important;
          }
        }
      }
    }

    .control-content {
      padding-top: 0 !important;
    }
  }

  .control-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.5rem 0;

    & > span {
      margin: 0 0.5rem;
      margin-left: 0;
    }

    .field-otp__label {
      margin-top: 0.8rem;
    }

    .field-otp__wrap {
      width: 100%;
      max-width: 320px;
      margin-top: 1rem;
      position: relative;
    }

    .otp-loader {
      margin-left: 0.5rem;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }

  @media only screen and (max-width: 767px) {
    .control-container {
      .field-otp__wrap {
        width: 90%;
      }
      .otp-loader {
        margin-left: 0;
      }
    }
    .field-otp__cont {
      & > div {
        width: calc((1 / 6 * 100%) - 0.2rem);
        margin-right: 0.4rem;
      }
      justify-content: flex-start;
    }
    .field-otp__input {
      min-width: calc((1 / 6 * 100%) - 1rem) !important;
      min-height: 40px !important;
      padding: 0.3em !important;
    }
    .field-otp__request {
      &.is-timeout {
        &:after {
          width: 100%;
        }
      }
    }
  }
}
