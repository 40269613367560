.form-choose {

  &--2 {
    .lm--switch,
    .error-block {
      flex-basis: calc(50% - 0.25rem);
      min-width: calc(50% - 0.25rem);
      max-width: calc(50% - 0.25rem);

      &:nth-child(2n+1) {
        margin-left: 0;
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }

  &--3 {
    .lm--switch,
    .error-block {
      flex-basis: calc(33% - 0.25rem);
      min-width: calc(33% - 0.25rem);
      max-width: calc(33% - 0.25rem);

      &:nth-child(3n+1) {
        margin-left: 0;
      }

      &:nth-child(3n+2) {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        flex-basis: calc(33% - 0.5rem);
        min-width: calc(33% - 0.5rem);
        max-width: calc(33% - 0.5rem);
      }

      &:nth-child(3n+3) {
        margin-right: 0;
        margin-left: 0.25rem;
      }
    }
  }

  .switches {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .lm--switch {
    &.is-disabled {
      opacity: 0.5;
    }
  }

  .lm--switch,
  .error-block {
    flex-grow: 1;
    margin: 0.25rem;
    margin-left: 0;

    &:only-child {
      max-width: calc(67% - 0.5rem);
    }

    .lm--switch-input {
      &:checked {
        &+.lm--switch-label {
          border-color: #00b0b2 !important;

          &:before {
            content: '';
            margin-top: -0.1rem;
            margin-right: 0.6rem;
          }
        }
      }

      &:focus {
        &+.lm--switch-label {
          border-color: #ced5dc;
        }
      }
    }

    .lm--switch-label {
      padding: 0.5rem 0.2rem;
      font-weight: 400;

      &:before {
        content: initial;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .lm--formItem-control {
    display: flex;
    flex-wrap: wrap;
  }
}
