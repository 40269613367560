.datepicker {
  border: 1px solid #ced5dc;
  border-radius: .3125rem;
  display:flex;
  flex-direction:row;
  max-width: 220px;

  input{
    border: none;
    flex-grow: 2;
  }

  .lm--button-picker{
    border: none;
    width: 25%;
  }
}

.is-error .datepicker{
  border-color: red;
}
