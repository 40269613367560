.ad-banner {
  display: inline-block;
  width: 586px;
  height: 100px;

  &-separate {
    display: flex;
    width: 586px;
    height: 200px;
    outline: none !important;
    border-radius: rem(5);
    box-shadow: 0 2px 10px 3px rgba(#e7ecef, 0.6);

    .ad-banner-img {
      flex-basis: 36%;
    }

    .ad-banner-title {
      display: flex;
      flex-basis: 64%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;

      h3 {
        font-weight: 500;
      }
    }
  }

  &-single {
    display: flex;
    width: 586px;
    height: 200px;
    position: relative;

    .ad-banner-title {
      width: 16rem;
      display: flex;
      position: absolute;
      flex-basis: 64%;
      flex-direction: column;
      align-items: flex-start;
      word-wrap: break-word;
      margin-right: 9%;

      h5 {
        font-weight: 600;
      }

      a {
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  figure {
    display: inline-block;
    background-color: #e0e0e0;
    line-height: 0;
    width: 100%;
    height: 100%;

    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
