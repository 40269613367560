.app-modal {
  &.app-confirmation_dialog {
    padding-left: 2rem;
    padding-right: 2rem;
    @media only screen and (min-width: 768px) and (max-height: 662px) {
      max-height: 600px;
    }
    .err-content {
      text-align: center;
    }
  }
  &.app-reminder_dialog {
    padding: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 772px;
    max-height: 80%;
    overflow: auto;
    .content {
      padding-top: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    .modal-ctas {
      width: 100%;
      justify-content: center;

      button {
        min-width: 175px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    .app-accordion {
      margin-bottom: 1rem;
    }
    .app-table {
      margin-bottom: 1.5rem;
      & ~ .app-table {
        margin-top: 1rem;
      }
    }
    small {
      margin-top: 1rem;
      display: block;
      font-size: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .content {
    display: block;
    width: 100%;
  }
  
  .sys-msg {
    display: block;
    font-size: 0.6rem;
    margin: 0 auto;
    text-align: center;
    padding: 0.5rem 1rem;
    max-width: 80%;
  }
}
