.app-contentnode {
  display: inline-block;
  // margin-bottom: 1.37rem;
  &__label {
    display: block;
    font-size: 1.25rem;
    color: #ffffff;
    opacity: 0.7;
  }
  &__value {
    display: block;
    font-size: 1.5rem;
  }
}
