.signature-popup {
  display: flex;
  flex-direction: column;
  height: 28vw;
  justify-content: space-between;
  width: 100%;
}

.signature-popup-action {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .action--right {
    display: flex;
    gap: 10px;
  }
}

.signaturePad {
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  height: 80%;
  margin: 0 auto;
}
