.thank-you {
  display: flex;
  width: 100%;
  padding-top: 100px;
  z-index: 100;
  background: none;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;

  @media screen and (min-width: 1025px) and (max-height: 690px) {
    padding: 5rem !important;
    position: relative !important;

    .ad-banner {
      position: relative !important;
      bottom: auto !important;
    }
  }

  .lm--box {
    background: none;
  }

  .thank-you--container {
    max-width: 400px;
    padding: 0 1rem;
    margin: auto;
  }

  .thank-you--image {
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .thank-you--desc {
    text-align: center;
    h3 {
      margin-bottom: 0.875rem;
      font-size: 1.7rem;
    }

    p {
      margin-bottom: 0.875rem;
    }
  }
}
