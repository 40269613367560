.app-accordion {
  display: block;
  width: 100%;
  .accordion--item {
    display: block;
    border-bottom: 1px solid #E7ECEF;
    &.is-active {
      .accordion--item-head {
        .accordion--item-icon {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
    .accordion--item-head {
      display: block;
      width: 100%;
      position: relative;
      padding: 0;
      
      cursor: pointer;
      .accordion--item-title {
        font-size: 1rem;
        font-weight: 500;
        padding: 1rem 0;
        margin-bottom: 0;
      }
      .accordion--item-icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        line-height: 0;
      }
    }
    .accordion--item-body {
      display: block;
      width: 100%;
      height: 0;
      overflow: hidden;
    }
    .accordion--item-content {
      padding: 0 1.5rem 1rem;
      ol, ul {
        li {
          padding-left: 1rem;
          padding-bottom: 0.5rem;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}