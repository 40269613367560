.form-upload_doc {
  .lm--formItem-label {
    min-height: 33px !important;
  }
}

.form-upload_photo {
  .lm--formItem-label {
    min-height: 47px !important;
  }
}
