.lm--checkbox {
  &.is-disabled {
    .lm--checkbox-label {
      &:before {
        background-color: #f7f7f7;
      }

      &:after {
        border-color: #cccfd2;
      }
    }
  }
}

.lm--checkbox-label,
.lm--radio-label {
  margin-right: 0;
  padding-right: 0;
  width: 100%;

  &:before {
    width: 1.5rem;
    height: 1.5rem;
    max-width: 1.5rem;
  }

  & > div {
    width: calc(100% - 3rem);
  }
}

.check-grouped {
  padding-left: 2.2rem;
}
