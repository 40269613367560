@import "~@lumi/css-variables/dist/index.scss";

.cui-choice {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  font-family: 'geomanist';
  font-size: 1rem;
  position: relative;

  * {
    box-sizing: border-box;
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translate(50%, -40%);
    border-radius: 50%;
    border: 1px solid $color-mint;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 0.6rem 0.5rem;
    background-color: $color-mint;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MiAoNjY4NjkpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnRpY2s8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iMDMtUmV0YWlsZXItU2FsZXMtVG9vbGtpdC0oQ29uc3VtZXIpIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiPgogICAgICAgIDxnIGlkPSIwMi1QYXJ0aWN1bGFycyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI1My4wMDAwMDAsIC0xNzQuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUHJvZ3Jlc3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcyLjAwMDAwMCwgMTY5LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InRpY2siIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Mi42NjY2NjcsIDYuNTAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTAsMi41IEwyLjUsNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi41LDUgTDcuMTAyMjMzNzYsMC4zOTc3NjYyNDMiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
  }

  &:first-child {
    margin-left: 0;
  }

  &.is-active {
    &:before {
      visibility: visible;
      opacity: 1;
    }

    .cui-choice__btn {
      border-color: $color-mint;
      color: $color-mint;
    }
  }

  &.is-disabled {
    &:before {
      background-color: rgba(#78d0d1, 1);
      border-color: rgba(#78d0d1, 1);
    }

    .cui-choice__btn {
      opacity: 0.5;
    }
  }

  &.with-icon {
    .cui-choice__btn {
      padding-right: 25%;
      small {
        line-height: 1.3;
        margin-top: 0.25rem;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #ced5dc;
    background-color: #ffffff;
    outline: none !important;
    font-size: inherit;
    z-index: 5;
    color: #4f5a60;
    text-align: left;
    &__icon{
      display: inline-block;
      height: 25px;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
    h4 {
      padding-bottom: 0.2rem !important;
    }
    small {
      line-height: 1.5;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: $color-bg;
    padding: 1rem;
    width: 100%;
    .lm--formItem-control {
      padding: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .cui-choice__content {
      padding: 0.8rem;
    }
  }
}
