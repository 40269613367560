.app-tabs {
  display: block;
  width: 100%;
  overflow: hidden;

  .tabs--nav {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0 !important;
      }
    }

    .tabs--nav-item {
      display: inline-flex;
      margin: 0.2rem 0.5rem;

      &.is-active {
        button {
          background-color: #B6DFe2;
        }
      }

      button {
        padding: 0.5rem 1.1rem;
        border-radius: 2rem;
        color: #4f5a60;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
