.app-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 450px;
  figure {
    margin-bottom: 0.2rem;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  p{
    max-width: 260px;
    text-align: center;
    margin-bottom: 1rem;
  }
}