$font-path: '~atlas/dist/fonts';

@import '~atlas/src/styles/settings/font-geomanist';
@import '~atlas/src/styles/tools/clearfix';
@import '~atlas/src/styles/tools/caret';
@import '~atlas/src/styles/tools/font-size';

@import 'styles/settings/colours';

@import 'styles/components/openaccount';
@import 'styles/components/dialog';
@import 'styles/components/display';
@import 'styles/components/kioskupload';
@import 'styles/components/menu';
@import 'styles/components/modal';
@import 'styles/components/upload';
@import 'styles/components/datepicker';
@import 'styles/components/myinfo';
@import 'styles/components/tooltip';
@import 'styles/components/accordion';
@import 'styles/components/tabs';
@import 'styles/components/table';
@import 'styles/components/timepicker';
@import 'styles/components/thankyou';
@import 'styles/components/bar';
@import 'styles/components/adbanner';
@import 'styles/components/sidebar';
@import 'styles/components/contentnode';
@import 'styles/components/esignature';

@import 'styles/atlas-fix';
@import 'styles/wiz/index.scss';
@import 'styles/form/index.scss';
@import 'styles/mobile.scss';
@import 'styles/ie.scss';
@import 'styles/kiosk.scss';
@import 'styles/overrides.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  overflow: initial;
}

body {
  background-color: #f3f6f7;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'geomanist', Arial;
  color: #4f5a60;
}

a {
  color: #00b0b2;
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'geomanist', Arial;
}

ol,
ul {
  margin-bottom: 16px;
  padding-left: 24px;
}

ol li,
ul li {
  padding-left: 4px;
}

h1 {
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  line-height: 1.666666667;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

p {
  margin-top: 0;

  a {
    display: inline;

    span {
      display: inline;
    }
  }
}

small {
  a {
    display: inline;

    span {
      display: inline;
    }
  }
}

.app-header {
  display: flex;
  position: absolute;
  top: -0.75rem;
  left: 0;
  width: 100%;

  // height: 50px;
  z-index: 100;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 1rem;

  .logo {
    display: inline-block;
    width: 100px;
    line-height: 0;

    img {
      width: 100%;
    }
  }
}

.app-main {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 147px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    background: -moz-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #aeeaeb 0%, #ffffff 100%);
  }
}

.highlight {
  font-weight: 500;
  color: #00b0b2;
}

.lm--button {
  position: relative;
  min-height: 44px;

  &.loading {
    span {
      opacity: 0;
    }
  }

  .btn-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card-error {
  display: flex;
  width: 300px;
  text-align: center;
  height: 300px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app-mobile-warn {
  display: none;
}

.browser-prompt {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4000;
  background-color: rgba(255, 255, 255, 0.85);
  justify-content: center;
  align-items: center;

  p {
    max-width: 250px;
    font-size: 1.2rem;
    text-align: center;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.Xprintable-border {
  .detail-selection-sd {
    margin-top: 20px;
  }

  .detail-section-empty {
    margin-top: 400px;
  }
}

.oa-status {
  display: block;
  padding: 1rem 0;
  text-align: center;
  max-width: 600px;
  padding: 1rem;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }

  .status-icon {
    display: inline-block;
    line-height: 0;
    margin-bottom: 1rem;
    width: 200px;

    img {
      display: block;
      width: 100%;
    }
  }
}
