.myinfo-prompt {
  padding: 2rem;

  // left: 2rem;
  position: relative;

  & > div {
    text-align: center;
    margin: 1rem 0;
  }

  .myinfo-logo {
    display: inline-block;
    width: 213px;
    margin-bottom: 1rem;
  }

  h2 {
    font-weight: 500;
  }

  p {
    margin: .5rem 0;
  }

  .lm--button {
    min-width: 175px;
    justify-content: center;
    align-items: center;
  }

  section {
    display: flex;
    position: relative;
    padding-bottom: 1rem;

    .myinfo-content {
      display: inline-block;
      text-align: left;
      color: #333333;

      & + .myinfo-logo {
        width: 300px;
        margin-bottom: 0;
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        padding: 1rem;

        img {
          width: 100%;
        }
      }
    }

    &.myinfo-header {
      padding: 1rem;
      background-color: #f3f3f3;
      margin-bottom: 1.5rem;
    }

    &.myinfo-features {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      align-items: flex-start;

      .item {
        min-width: 33%;
        padding: 1rem;

        .item-icon {
          display: inline-flex;
          border: 1px solid #333333;
          height: 80px;
          width: 80px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        }

        .item-title {
          display: block;
          padding: 0.7rem;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            font-size: 0.8rem;
            text-align: center;
            margin-bottom: 0.2em;
          }
        }
      }
    }

    &.myinfo-about, &.myinfo-steps {
      flex-direction: column;
      text-align: left;
    }

    &.myinfo-steps {
      ol {
        list-style: none;
        counter-reset: steps-counter;
        display: block;
        margin: 1rem 0;
        padding: 0;
        text-align: left;
        padding-left: 3rem;

        li {
          counter-increment: steps-counter;
          padding-left: 1rem;
          padding-bottom: 0.8rem;
          position: relative;

          &:before {
            content: "Step " counter(steps-counter) ":";
            font-size: inherit;
            position: absolute;
            top: 0;
            left: 3px;
            transform: translateX(-100%);
            font-weight: 500;
            width: 50px;
          }
        }
      }
    }
  }

  .myinfo-ctas {
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: 1rem;
    }
  }
}

.myinfo-terms {
  padding: 0.5rem 0.2rem 0;

  h4 {
    text-align: center;
    margin-bottom: 0.6rem;
  }

  ol {
    ol {
      list-style-type: lower-alpha;
      margin: 0.4rem 0;
    }

    li {
      margin-bottom: 0.4rem;
    }
  }
}

.myinfo-prompt-box {
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ced5dc;
  justify-content: center !important;
  align-items: center !important;

  .myinfo-logo {
    // width: 120px !important;
    margin-right: 0.2rem !important;
    margin-left: 0.2rem;
    margin-top: 0.4rem;
  }

  .myinfo-text {
    display: none;
  }

  .tooltip {
    margin-left: 0.5rem !important;
    line-height: 0;

    .tooltip-target {
      line-height: 0;
    }
  }

  .u-grid {
    align-items: center;
  }
}

.myinfo-btn {
  background: none !important;
  min-height: initial !important;

  span {
    line-height: 1 !important;
  }
}

.oa {
  .signup--form {
    &.signup--company {
      .myinfo-prompt-box {
        .myinfo-logo {
          width: 70px !important;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
