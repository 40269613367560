// Fixing atlas bugs not overrides
// Remove negative margins
.u-grid {
  margin: 0;
}

.lm--header {
  margin-bottom: 0;
}

.lm--switch-input[disabled] + .lm--switch-label:hover {
  border-color: #ced5dc;
  color: #9ba6aa;
  cursor: not-allowed;
}

.lm--footer {
  height: 64px;
}

.lm--table {
  &-actions {
    & > div {
      margin-right: rem(8);

      &:last-child {
        margin-right: 0;
      }
    }

    a, button {
      padding: rem(9) rem(16) rem(9);
    }
  }
}

.lm--alert {
  z-index: 2000;
}

.lm--button {
  outline: none !important;

  &.link-like {
    display: inline;
    background: none;
    padding: 0;
    margin: 0;
    min-height: initial;
    min-width: initial;
    font-size: inherit;
    font-weight: inherit;
    line-height: initial;
    vertical-align: initial;

    span {
      font-weight: inherit;
    }

    &.lm--button--primary {
      color: #00b0b2;
    }
  }
}
