.app-table {
  &__resp-horiz {
    width: 100%;
    overflow-x: auto;
  }
  td {
    text-align: right;
    padding: 0.8rem;
    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
  thead {
    td {
      &:first-child {
        text-align: left;
      }
      font-size: 0.75rem;
      color: #818A91;
    }
  }
  tbody {
    td {
      &:first-child {
        text-align: left;
      }
    }
    tr {
      border-top: 0;
      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: #F3F6F7;
      }
    }
  }

}