.ie-legacy {
  .app-main {
    width: calc(100vw - 17px);
    // justify-content: flex-start;
    &.oa {
      .terms-node {
        .terms-control {
          width: 32px;
          .lm--checkbox-label {
            width: 32px;
            height: 24px;
            &:before {
              height: 24px;
            }
          }
        }
        
        .terms-text {
          width: calc(100% - 32px);
        }
      }
      
      .signup {
        width: 100%;
        display: block;
        margin: 0;

        .page-content {
          width: 100%;

          .signup--form {
            width: 100%;

            .form-container {
              width: 100%;
            }

            .lm--formItem--inline {
              .lm--formItem-label {
                height: 44px;
              }
            }
          }
        }
      }
    }
  }

  .app-modal {
    display: block;

    &.app-reminder_dialog {
      .modal-ctas {
        button {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    &.app-confirmation_dialog {
      min-height: 0;
      padding: 2.3rem 2rem; 
      -ms-overflow-style: none;
      overflow: auto;
      &#myinfo-tnc-prompt {
        .modal-ctas {
          display: block;
          width: 180px;
          margin: 0 auto;
          text-align: center;
          button {
            display: inline-block !important;
            max-width: 180px;
          }
        }
      }
      .modal-ctas {
        display: block;
        text-align: center;
        button {
          display: inline-block;
          width: auto;
          max-width: 100px;
          margin: 0.25rem;
        }
      }
    }

    .modal-ctas {
      button {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  .lm--select {
    &::-ms-expand {
      display: none;
    }
  }

  .lm--button {
    &.loading {
      .btn-loader {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 0 0 0;
      } 
    }
  }

  .lm--checkbox-label,
  .lm--radio-label {
    &:before {
      width: 32px;
      height: 24px;
      &:before {
        height: 24px;
      }
    }
  }

  .lm--formItem {
    .lm--formItem-control {
      .field-loader {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 0 0 0;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg) translate(-50%, -50%);
    }

    to {
      transform: rotate(360deg) translate(-50%, -50%);
    }
  }
}
