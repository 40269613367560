.app-backdrop {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($space-colour, 0.4);
}

.app-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  min-width: 320px;
  max-width: 640px;
  min-height: 200px;
  background-color: #fff;
  outline: none !important;
  padding: rem(16);
  border-radius: rem(5);
  box-shadow: 0 2px 20px 1px rgba(#e7ecef, 0.6);

  &.modal-terms {
    width: 60vw;
    height: 70vh;
    overflow: hidden;
    padding-right: 0;

    .terms-container {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-right: 1rem;
    }
    .terms-and-conditions {
      & > div {
        margin-bottom: 1rem;
      }
    }
    .extContents {
      & > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  &.kiosk-mode {
    overflow: visible;

    .modal-close {
      top: -2.5rem;
      right: -2.5rem;
      border-radius: 50%;
      padding: 12px 14px;
      background-color: #fff;
    }
  }

  .modal-close {
    display: inline-block;
    position: absolute;
    outline: none !important;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
  }

  .modal-ctas {
    display: flex;
    flex-direction: row;
    margin-top: rem(20);

    button {
      margin-right: rem(10);
      outline: none !important;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modal-footnote {
    display: block;
    width: 100%;

    .fn-item {
      display: flex;
      flex-direction: row;
      padding-bottom: 0.4rem;
      justify-content: center;
      align-items: center;

      &:last-child {
        padding-bottom: 0;
      }

      .circle {
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 100%;
        width: 5px;
        height: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7rem;
      }
    }
  }

  h3 {
    margin-bottom: 0.4em;
  }

  .modal-arrow {
    display: inline-block;
    position: absolute;
    background-color: inherit;
    width: 20px;
    height: 20px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translate(20%, 50%);
    z-index: 1;
    border-bottom: 0;
    border-right: 0;
    box-shadow: -5px -5px 10px 1px rgba(0, 0, 0, 0.02);

    &.right_top {
      bottom: 100%;
      right: 20px;
    }

    &.left_top {
      bottom: 100%;
      left: 20px;
    }

    &.right_bottom {
      top: 100%;
      right: 20px;
    }

    &.left_bottom {
      top: 100%;
      left: 20px;
    }

    &.center_top {
      left: 50%;
      bottom: 100%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.center_bottom {
      left: 50%;
      top: 100%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.left_middle {
      left: 20px;
      bottom: 50%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.right_middle {
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translate(20%, 50%);
    }
  }
}
