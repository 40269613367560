.kiosk-mode {
  .app-main {
    width: calc(100vw - 18.59vw);
    margin-left: 18.59vw;
  }

  .sidebar {
    .btn-endsession {
      color: #fff;
      position: relative;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 69px;
      width: 100%;
      outline: none;
      padding: 0.4em;

      &:before {
        display: inline-block;
        position: relative;
        content: '';
        width: 15px;
        height: 15px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        transform-origin: center center;
        transform: rotate(45deg);
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .sidebar {
      display: none;
    }

    .app-main {
      width: 100%;
      margin-left: 0;
    }
  }
}

.app-modal {
  &.kiosk-mode:not(.tooltip_pop) {
    left: calc(50% + (18.59vw / 2)) !important;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    &.kiosk-mode:not(.tooltip_pop) {
      left: 50% !important;
    }
  }
}

.app-backdrop {
  &.kiosk-mode {
    width: calc(100% - 18.59vw);
    left: 18.59vw;
    right: auto;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    &.kiosk-mode {
      width: 100%;
      left: 0;
      right: 0;
    }
  }
}

.app-datepicker {
  &.kiosk-mode {
    width: 380px;
    left: 50% !important;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    &.kiosk-mode {
      width: 90%;
    }
  }
}

.signup--payment {
  .form-cover {
    &.kiosk-mode {
      display: flex;
      justify-content: center;
    }
  }
  .cover-content {
    &.kiosk-mode {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}
