.oa-mobile {
  .app-header {
    display: none;
  }

  .app-main {
    .signup {
      position: relative;

      .page-content,
      .signup--form,
      .form-container {
        position: static;
      }
    }

    &:before {
      display: none;
    }
  }

  .oa {
    .signup {
      padding: 1rem;
      padding-bottom: 143px;
      width: 100%;

      .page-title {
        padding-top: 0;

        h2 {
          display: none;
        }
      }

      .page-content {
        padding: 0;
      }
    }

    .myinfo-prompt {
      .myinfo-header {
        padding: 0;
        background: none;

        h2 {
          font-size: 1.25rem;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .open-account {
      margin-top: 1rem;
    }

    .oa {
      .signup {
        .signup--form {
          .form-cta {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            border-top: 1px solid rgba(227, 227, 227, 1);
            background-color: #f7f7f7;
            padding: 1rem;
            z-index: 10000;

            button {
              margin: 0;
              width: 100%;
              margin-bottom: 0.5rem;
              order: 2;

              &[type="submit"] {
                order: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  input {
    &:disabled {
      -webkit-text-fill-color: #5b686d !important;
      color: #151819;
      opacity: 1;
    }

    appearance: none !important;
  }

  .app-mobile-warn {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: 600;
      text-align: center;
    }

    h3 {
      text-align: center;
    }
  }

  .open-account {
    width: 100%;
    padding: 1rem;
    padding-bottom: 4rem;
    max-width: 400px;
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);

    h1 {
      br {
        display: none;
      }
    }
  }

  .lm--card {
    &.page-title {
      .lm--card-item {
        padding: 0.5rem 0;
      }
    }
  }

  .oa {
    .signup {
      padding: 4rem 1rem;
      padding-bottom: 5rem;
    }

    .signup--form {
      left: auto;
      padding: 0;

      &.signup--confirmation {
        padding: 0;
      }

      &.signup--print {
        padding: 0;
      }

      header {
        margin-bottom: 0.5rem;

        .head-content {
          width: 100%;
        }
      }

      form {
        .lm--formItem {
          &.form-myinfo {
            margin-bottom: 1rem !important;

            .lm--formItem-label {
              display: none;
            }
          }
        }

        .lm--formItem--inline {
          .lm--formItem-label {
            width: 100% !important;
            max-width: 100% !important;
            justify-content: flex-start;
            min-height: initial;
            padding-right: 0;
            padding-bottom: 0.3rem;
            text-align: left;
          }
        }
      }

      .detail_section {
        .detail_section--group-box {
          .upload-input {
            .upload_field {
              width: 100%;
            }
          }
        }

        .label {
          width: 100%;
          text-align: left;
          padding: 0;
          font-size: 0.7rem;
        }

        .data {
          width: 100%;
          padding: 0;
        }

        .upload-input {
          display: block;
          height: 100%;
          padding: 0 !important;
          padding-top: 0.5rem !important;

          .mock_file {
            display: flex;
            flex-direction: row;
          }

          .upload-label {
            width: auto;
            display: block;
            text-align: left;
            min-height: initial;
            padding-bottom: 0.4rem;
          }
        }

        .lm--button-picker {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .ad-banner {
      max-width: 586px;
      max-height: 100px;
      width: 100%;
      height: auto;
      position: relative;
      top: 10px;
    }

    .myinfo-prompt {
      .myinfo-ctas {
        & > div {
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0;

            span {
              width: 100%;
            }

            img {
              width: 100%;
            }
          }

          #btn--use-manual {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0.7rem 1rem;
            border: 1px solid #00b0b2;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .lm--formItem--inline {
    .lm--formItem-control {
      width: 100%;
    }
  }

  .lm--button-picker {
    max-width: 100%;
  }

  .form-cta {
    align-items: flex-end;
    text-align: right;
    padding-top: 1rem;

    .lm--button {
      min-width: 130px;
    }
  }

  .form-choose {
    .lm--switch {
      &:only-child {
        max-width: 100%;
      }
    }
  }

  .signup {
    .page-title {
      h2 {
        margin-bottom: 2rem !important;
        padding: 1rem 0;
        text-align: left !important;
      }

      .lm--timeline {
        counter-reset: steps-counter;
        margin: 0;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;

        li {
          display: none;
          counter-increment: steps-counter;
          text-align: left;
          padding: 0;
          font-size: 1.2rem;

          &:before {
            background: none;
            border: none;
            border-radius: 0;
            box-shadow: none;
            width: auto !important;
            height: auto !important;
            position: relative;
            left: auto;
            transform: translate(0, 0);
            content: "Step " attr(data-step) " of " attr(data-total);
            display: block;
            font-size: 0.8rem;
            color: rgba(129, 138, 145, 1);
          }

          &:after {
            background: none;
            border: none;
            border-radius: 0;
            box-shadow: none;
            width: auto !important;
            height: auto !important;
          }

          &.is-active {
            display: block;
          }
        }
      }
    }

    .page-content {
      padding: 0;
    }
  }

  .myinfo-prompt {
    left: auto;
    padding: 0;

    h2 {
      line-height: 1.4;
    }

    section {
      &.myinfo-header {
        flex-direction: column;

        .myinfo-content {
          order: 2;
          text-align: center;
        }
      }

      &.myinfo-features {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        .item {
          display: block;
          max-width: 50%;
        }
      }

      .myinfo-content {
        & + .myinfo-logo {
          order: 1;
          width: 150px;
          margin: 0 auto;
        }
      }
    }
  }

  .app-backdrop {
    opacity: 0.4 !important;
  }

  .app-datepicker {
    position: fixed !important;
    bottom: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 95% !important;
  }

  .DayPicker {
    width: 100%;

    .DayPicker-NavBar {
      top: 0;
      right: 0;
    }
  }

  .DayPicker-Month {
    margin: 0 !important;
  }

  .DayPicker-Caption {
    padding: 0 !important;
  }

  .DayPicker-NavButton {
    // right: 0.5rem;
    // top: 0.25em;
  }

  .success-bar {
    padding: 0;
    margin: 1rem 0;

    .myinfo-logo {
      margin-left: 0.2rem;
      margin-top: 0.2rem;
    }

    span {
      margin: 0.4rem 0;
      display: inline;
    }
  }

  .app-modal {
    max-height: 70vh;
    width: 85%;
    overflow: auto;
    display: block;
    padding: 1rem !important;

    &.app-reminder_dialog {
      max-width: 90vw;
      padding: 1rem;

      .content {
        padding-top: 1rem;
      }
    }

    &.app-confirmation_dialog {
      .modal-ctas {
        flex-direction: column;

        button,
        .lm--button {
          justify-content: center;
          margin: 0.2rem 0;
        }
      }
    }

    .app-tabs {
      .tabs--nav {
        ul {
          overflow-x: auto;
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .check-grouped {
    padding-left: 0;
    padding-top: 0.5rem;
  }

  .thank-you {
    height: auto;
    padding-top: 100px;
    padding-bottom: 5rem;
    position: relative;

    .ad-banner {
      max-width: 320px;
      max-height: 250px;
      height: auto;
      top: auto;
      bottom: auto;
      left: auto;
      transform: none !important;

      &-separate {
        max-width: 320px;
        height: 250px;
        flex-direction: column;
        transform: none !important;
        margin: 0 auto;

        .ad-banner-title {
          justify-content: flex-start;
        }

        .ad-banner-img {
          width: 80%;
        }
      }

      &-single {
        max-width: 320px;
        height: 250px;
        margin-top: 2rem;
        transform: none !important;
        margin: 0 auto;

        .ad-banner-title {
          align-items: center;
          text-align: center;
          margin-bottom: 1.7rem;
          margin-right: 0%;

          a {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  input {
    &:disabled {
      -webkit-text-fill-color: #5b686d !important;
      color: #151819;
      opacity: 1;
    }

    appearance: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) {
  input {
    &:disabled {
      -webkit-text-fill-color: #5b686d !important;
      color: #151819;
      opacity: 1;
    }

    appearance: none !important;
  }

  .app-modal {
    &.kiosk-mode:not(.tooltip_pop) {
      max-width: 750px !important;
      max-height: 80% !important;
    }

    &.app-confirmation_dialog {
      width: 80%;
      max-width: 640px;
      max-height: 80%;
    }
  }
}

//for device like samsung galaxy S7/S8 and Iphone 5/6/7/8
@media only screen and (max-width: 380px) {
  .DayPicker {
    .DayPicker-WeekdaysRow {
      .DayPicker-Weekday {
        margin: 0px;
      }
    }

    .DayPicker-Day {
      margin: 0px;
    }

    .DayPicker-NavButton {
      // right: -0.5em;
      // top: 0.25em;
    }
  }
}

//there is not enough space for MyInfo logo below 394px
@media only screen and (max-width: 394px) {
  .myinfo-prompt-box {
    .myinfo-logo,
    .myinfo-tooltip {
      display: none;
    }

    .myinfo-text {
      display: inline;
    }
  }
}

/* Mobile View */
.oa-normal {
  @media only screen and (max-width: 767px) {
    .app-header {
      display: flex;
      position: absolute;
      top: -0.75rem;
      left: 0;
      width: 100%;
      z-index: 100;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      padding: 1rem;

      .logo {
        display: inline-block;
        width: 100px;
        line-height: 0;

        img {
          width: 100%;
        }
      }

      .cui-logo {
        & svg {
          width: 8.4rem;
        }
      }
    }
  }
}
