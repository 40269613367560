@import "./form-choose";
@import "./form-check";
@import "./form-date";
@import "./form-time";
@import "./form-upload";
@import "./form-myinfo";
@import "./form-otp";

.lm--form {
  background-color: white;
  padding: rem(24);

  &Item {
    > .lm--switch ~ .lm--switch {
      margin-left: 1rem;
    }
  }

  &Item--inline {
    .lm--formItem-label {
      width: 240px;
      text-align: right;
    }

    .lm--formItem-control {
      width: calc(100% - (240px + 1.438rem));
      text-align: left;
      .cui-field_content {
        cursor: pointer;
      }
    }
  }

  .form--title {
    margin: rem(24);
  }
}

.lm--switch {
  flex-grow: 1;

  label {
    width: 100%;
    color: inherit !important;
  }
}

.button-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button:first-child {
    margin-right: 1rem;
  }
}

.lm--inputGroup {
  &-suffix {
    position: absolute;
    right: 0;
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 rem(10) 0 rem(10);
    font-size: rem(18);
  }

  &-field-suffix {
    padding-right: 2.5rem;
  }
}

.lm--formItem {
  &.is-error {
    .lm--formItem-control {
      .field-loader {
        top: 23px;
      }
    }
  }

  &.with-cta {
    .lm--formItem-control {
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        &:not(.mobile_prefix) {
          width: 50% !important;
        }

        &.number {
          width: calc(50% - 46px) !important;
        }
      }
    }
  }

  &.with-note {
    margin-top: calc(1rem + 1rem) !important;
    padding-top: 2rem !important;

    .lm--formItem-control {
      position: relative;
    }
  }

  .lm--formItem-control {
    position: relative;

    .control-content {
      padding-top: 0.5rem;

      p {
        margin-top: 0 !important;
        margin-bottom: 0.2rem !important;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .control-counter {
      text-align: right;
    }

    .lm--formItem-uppercase {
      text-transform: uppercase;
    }
  }

  .field-cta {
    display: inline-block;
    flex-basis: 50%;
    flex-grow: 1;
    padding: 0.5rem 1rem;
  }

  .field-note {
    display: flex;
    flex-basis: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 0.6rem 0;
  }

  .field-loader {
    display: inline-block;
    position: absolute;
    top: 23px;
    right: 1rem;
    transform: translateY(-50%);
    line-height: 0;
  }
}

.field-item {
  &--content {
    .lm--formItem-control {
      display: inline-flex;
      align-items: center;
      min-height: 44px;
    }
  }
}

.lm--select {
  &:disabled {
    color: #4f5a60;
    border-color: #e7ecef;
    background-color: #f3f6f7;
    cursor: not-allowed;
  }
}

.lm--button-picker {
  display: flex;
  width: 100%;
  border: 1px solid #ced5dc;
  border-radius: 0.3125rem;
  position: relative;
  z-index: 1;
  max-width: 220px;
  font-weight: 400;

  &:disabled {
    color: #4f5a60;
    border-color: #e7ecef;
    background-color: #f3f6f7;
    cursor: not-allowed;
  }

  &:focus {
    border-color: #00b0b2;
    outline: none;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
}

.form-datetime {
  .form-datetime_container {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .error-block {
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }

  .datetime-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
  }

  .lm--formItem-control {
    display: flex;
    flex-direction: column;

    .lm--button-picker {
      margin: 0 0.25rem;
      min-width: calc(33% - 0.5rem);
      max-width: 172px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  small {
    display: block;
    margin-top: 0.2rem;
    font-size: 0.8125rem;
  }
}

.form-cta {
  justify-content: flex-end;
  padding-top: 2rem;

  .lm--button {
    min-width: 160px;
    flex-shrink: 0;
    margin: 0 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }
  span {
    font-weight: 600;
  }
}

.field-dropdown-control {
  width: 100%;
  text-align: left;
  .cui-field_content {
    cursor: pointer;
  }
}

.cui-field-dropdown__modal {
  &.cui-modal {
    min-width: unset;
    border-radius: 5px;
    max-height: 500px;
  }
}

.field-dropdown__options {
  overflow-y: auto;
  height: 300px;
  background-color: #fff;
}

.field-dropdown__option-wrapper {
  font-size: 1rem;
}

.cui-field-dropdown__wrapper {
  cursor: pointer;
}

input {
  appearance: none !important;
}

.lm--button {
  span {
    font-weight: 600;
  }
}
