.wiz-error {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  .error-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border: 1px solid #ff0000;
    background-color: #ff0000;
    border-radius: 50%;
    width: 30px; 
    height: 30px;
    padding: 0.1rem;
    margin-right: 1rem;

  }
  .error-content {
    display: inline-flex;
    font-size: 1rem;
    width: calc(100% - (30px + 1rem));
  }
}
