/* Fix Time picker text color */
.app-menu ul li {
  .btn--oa.lm--button--primary {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &.active .btn--oa.lm--button--primary {
    color: #00b0b2;
  }
}

/* Fixed Button-related components */
.btn--oa {

  /* Fixed Text Color */
  &.lm--button--primary:disabled {
    color: #9ba6aa;
    background: #e7ecef;
  }

  /* Fixed Button Backward or Cancel Type */
  &.lm--button--primary:not(:disabled),
  &.lm--button--danger {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  /* Fixed Button Border */
  &.lm--button--secondary {
    color: #00b0b2;
    border: 1px solid #00b0b2;
  }

  /* Fixed Button Tick */
  &.lm--switch-input {
    &+.lm--switch-label {
      border-color: #ced5dc;

      &:hover {
        border-color: #158693;
      }
    }

    &:checked {
      &+.lm--switch-label {
        -webkit-align-items: center;
        align-items: center;

        &::before {
          width: 8px;
          height: 13px;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  /* Fixed Hovering Border */
  &.lm--button--secondary:hover {
    border-color: #158693;
  }

  /* Fixed Checkbox */
  &.lm--checkbox-label {
    margin-right: 0;
    padding-right: 0;

    &::before {
      background-color: #fff;
    }
  }

  /* Fixed Inner Calender Buttons */
  &.lm--button-picker {
    color: #454f54;
    border: 1px solid #ced5dc;
    font-weight: 400;
    justify-content: inherit;

    &:hover {
      color: inherit;
    }
  }
}

/* Fixed Calender Border */
.app-datepicker .DayPicker .DayPicker-Caption {
  select {
    border-color: #ced5dc;
  }
}

/* Fixed Calender Select Day */
.app-datepicker .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff !important;
}

.cui-choice {
  &::before {
    border: 1px solid #00b0b3 !important;
    background-color: #00b0b3 !important;
  }
}

/* Fixed Elements during print */
@media print {
  @page {
    size: auto;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: Arial, geomanist, sans-serif;
    color: #4f5a60;
    margin: 0;
    padding: 2rem 0;
  }

  .for-print {
    display: block;
  }

  .print-container {
    width: 100%;
    padding: 2rem 0;
  }

  #printable {
    .print-pagebreak {
      page-break-before: always;
    }

    .printable-border {
      border: 1px solid #ced5dc;
      padding: 3rem 1rem 1rem;
    }

    max-width: 588px;
    margin: 0 auto;

    .page-title {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 2.5rem;
      margin-top: 1rem;
    }

    .terms-and-conditions {
      display: block;
      margin-top: 120px;
      text-align: left;
      padding: 1rem 0;

      .extTitle {
        font-size: 18px;
        font-weight: 500;
        margin-top: 16px;
        margin-bottom: 10px;
      }
    }

    .logo {
      display: block;
      text-align: left;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;

      img {
        width: 150px;
        display: inline-block;
      }
    }

    .signature-nodes {
      display: flex;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .signature-node {
        margin: 0 1rem;
        display: inline-block;
        width: 100%;
        padding: 5rem 0 0;

        .sn-line {
          display: block;
          width: 100%;
          padding-bottom: 0.5rem;
          border-top: 1px dotted #979797;
        }

        .sn-by {
          font-size: 0.8rem;
        }
      }
    }

    .detail_section {

      .detail_section--content,
      .detail_section--title {
        .label {
          width: 45% !important;
        }

        .data {
          width: 55% !important;
        }
      }

      .detail_section--title {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }

      .detail_section--content {
        padding-bottom: 1rem;

        .label {
          width: 45%;
          font-size: 0.9rem;
        }

        .data {
          width: 55%;
          font-size: 0.9rem;
        }

        .u-grid {
          padding-bottom: 0.5rem;
        }

        span {
          display: block;
        }

        .thumb {
          display: inline-block;
          width: 64px;
          height: 49px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 0.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .detail_section--checks {
        .u-grid {
          padding-bottom: 0;

          .u-cell {
            padding-left: 1px;
            padding-right: 0;
          }
        }

        .lm--formItem-label {
          display: none;
        }

        .lm--checkbox {
          .lm--checkbox-label {
            font-size: 0.8rem;

            &::before {
              flex-basis: 2.5rem;
            }
          }
        }
      }
    }
  }
}
