.success-bar {
  display: block;
  text-align: left;
  padding: 0.5rem;
  position: relative;
  margin: 0.5rem 0;
  &:before {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MiAoNjY4NjkpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnRpY2s8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iMDMtUmV0YWlsZXItU2FsZXMtVG9vbGtpdC0oQ29uc3VtZXIpIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiPgogICAgICAgIDxnIGlkPSIwMi1QYXJ0aWN1bGFycyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI1My4wMDAwMDAsIC0xNzQuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUHJvZ3Jlc3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcyLjAwMDAwMCwgMTY5LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InRpY2siIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Mi42NjY2NjcsIDYuNTAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTAsMi41IEwyLjUsNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi41LDUgTDcuMTAyMjMzNzYsMC4zOTc3NjYyNDMiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: 4px 6px;
    background-size: 10px 8px;
    background-color: #00b0b2;
    position: relative;
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    border-radius: 50%;
    transform: scale(1.2);
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    color: #00b0b2;
  
  }
  .myinfo-logo {
    max-width: 60px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.4rem;
  }
}