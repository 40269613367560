.oa {
  background-color: #fff;

  &.signup {
    .page {
      min-height: calc(100vh - 156px);
    }

    .lm--timeline {
      li {
        flex: 0 1 150px !important;
      }
    }

    .signup-header_content {
      display: block;
      width: 100%;
      margin-top: 4rem;

      // margin-bottom: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      border-bottom: 1px solid rgba(231, 236, 239, 1);

      h4 {
        font-size: 1rem;
      }
    }

    .signup-thankyou {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100vh - (156px + 4rem));

      .thank-you-image {
        text-align: center;
        margin-bottom: 1rem;
      }

      .thank-you-desc {
        text-align: center;
        max-width: 287px;
        margin: 0 auto;

        h3 {
          margin-bottom: 0.875rem;
        }

        p {
          margin-bottom: 0.875rem;
        }
      }
    }
  }

  .thank-you {
    .ad-banner {
      position: relative;
      bottom: auto;
      left: auto;
      transform: translateX(-18%);
      margin: 0.5rem 0;
      margin-top: 2rem;

      & + .ad-banner {
        margin-top: 0.5rem;
      }

      &-separate,
      &-single {
        transform: translateX(-18%);
        margin-top: 0.5rem;
        justify-content: center;
        align-items: center;
      }

      &-single {
        @media only screen and (max-width: 767px) {
          align-items: flex-end;
        }
      }
    }
  }

  .ad-banner {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .signup--confirmation {
    &.signup--form {
      padding: 0 5.84rem;
    }
  }

  .signup--upload {
    small {
      display: block;
      margin: 1rem 0;
    }

    .upload_field {
      width: initial;
    }

    .check-grouped {
      .upload-input {
        margin: 0.5rem 0;

        .upload-label {
          width: 120px;
          justify-content: flex-start;
        }
      }
    }

    &.signup--form {
      form {
        .lm--formItem {
          .lm--formItem-label {
            &.subtitle {
              padding-top: 1rem;
            }
          }
        }
      }
    }
  }

  .signup--form {
    // padding: 0 8.875rem 0 4.875rem;
    // padding: 0 4.625rem;
    padding: 0 2.5rem;
    position: relative;

    // left: -2rem;
    &.signup--confirmation {
      left: 0;
    }

    &.signup--particulars {
      .field-otp {
        margin-top: 0;
      }
    }

    &.signup--payment {
      .detail_section--checks {
        margin-top: 2rem;
      }
    }

    &.signup--print {
      position: relative;
      text-align: center;
      left: 0;
      padding: 0 5.84rem;

      #print-frame {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: -100%;
        left: 0;
      }

      #printable {
        border: 1px solid #ced5dc;
        padding: 1rem;
        position: relative;
        z-index: 10;
        margin: 1rem 0;

        .page-title {
          font-size: 1rem;
          text-align: center;
        }
      }

      .btn-print {
        margin-bottom: 0.2rem;

        & > span {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .app-icon {
            margin-right: 0.3rem;
          }
        }
      }

      #print-field_error {
        margin-top: 0;
      }

      .signup-summary {
        display: block;
        margin: 3rem 0;
      }
    }

    .signup--head {
      text-align: left;
    }

    .signup-summary {
      display: block;
      margin: 1rem 0;
    }

    form {
      &.form-container {
        position: relative;

        .note {
          margin-bottom: 2rem;
        }

        .form-cover {
          display: flex;
          position: absolute;
          top: 0;
          left: 0rem;
          width: 100%;
          height: 100%;
          z-index: 1000;
          justify-content: center;
          align-items: flex-start;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 2rem;

          .cover-content {
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        p {
          margin: 1rem 0;
        }

        textarea {
          &#premise_description {
            resize: none;
          }
        }
      }

      .lm--formItem {
        margin-bottom: 2rem;

        .lm--formItem-label {
          display: inline-flex;
          justify-content: flex-end;
          align-items: center;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 1rem;

          &.subtitle {
            font-size: 1rem;
            font-weight: 500;
          }
        }

        p {
          margin-bottom: 1rem;

          & + p {
            margin: 0.5rem 0;
          }
        }
      }
    }

    header {
      margin-bottom: 1rem;

      .head-content {
        width: calc(100% - (240px + 1.438rem));
        padding-top: 0.8rem;
        font-size: 1rem;

        p {
          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .lm--formItem-control {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 44px;

        & > div {
          width: 100%;
        }

        & > p {
          width: 100%;
        }
      }

      .lm--formItem-label {
        & + .lm--formItem-control {
          .lm--checkbox {
            padding-top: 0.6rem;
          }
        }
      }
    }

    .lm--formItem {
      &.split {
        .error-block {
          display: inline-block;
          width: 100%;
        }
      }

      .lm--formItem-control {
        .upload-input {
          &.upload-img {
            width: 80px;
            height: 49px;
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
          }

          &.upload-doc + .upload-doc {
            margin-top: 0.5rem;
          }
        }

        .split-input {
          display: inline-block;
          width: 50%;

          &.first {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
          }

          &.second {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .mobile_prefix {
          width: 46px;
          display: inline-block;
          text-align: center;
          border-radius: 5px 0px 0px 5px;
          border-right: 0px;
          padding: 0.6875rem 0.5rem;
        }

        .number {
          width: calc(100% - 46px);
          display: inline-block;
          border-radius: 0px 5px 5px 0px;
        }
      }
    }

    .lm--formItem--inline {
      margin-bottom: 0 !important;

      .lm--formItem-label {
        width: calc(240px + 1.438rem);
        padding-right: 1.438rem;
        padding-bottom: 1rem;
        min-height: 44px;
        margin-bottom: 0 !important;
      }

      .lm--formItem-control {
        .notes {
          display: block;
          padding-top: 0.5rem;

          & > div {
            padding-bottom: 0.5rem;

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .detail_section {
      .detail_section--title {
        margin-bottom: 1rem;
      }

      .detail_section--content {
        padding-bottom: 2rem;

        .u-grid {
          padding-bottom: 0.5rem;
        }

        span {
          display: block;
        }

        .thumb {
          display: inline-block;
          width: 64px;
          height: 49px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 0.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .node-item--block {
        padding: 0.8rem 0;

        &:last-child {
          padding-bottom: 0;
        }

        h4 {
          padding-bottom: 0.5rem;
        }

        p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .upload-input {
          padding: 0.5rem 0;
        }
      }

      .node-item--ctas {
        padding: 0.5rem;
        padding-left: 2rem;

        button,
        a {
          display: inline-block;
        }
      }

      .detail_section--group-box {
        display: block;
        text-align: left;
        padding: 1rem;
        border: 1px solid #dedede;
        border-radius: 5px;
        margin-bottom: 1rem;

        .field-otp {
          margin-bottom: 10px !important;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .node-item--block {
          &:first-child {
            padding-top: 0;
          }
        }

        .lm--formItem {
          .lm--formItem-label {
            min-width: 170px;
            max-width: 235px;
          }
        }

        .upload-input {
          margin-top: 0.5rem;

          .upload-label {
            width: 120px;
            justify-content: flex-start;
            text-align: left;
            padding-right: 1rem;
          }

          .upload_field {
            width: calc(100% - (120px + 1.438rem));
            flex-grow: 1;
          }
        }

        .check-grouped {
          .lm--formItem {
            .lm--formItem-label {
              width: 120px;
              justify-content: flex-start;
            }
          }
        }
      }

      .detail_section--checks {
        display: block;
        border-radius: 5px;
        background-color: #f3f3f3;
        padding: 1rem;
        text-align: left;

        .u-grid {
          padding-bottom: 0;

          .u-cell {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .lm--formItem-label {
          display: none;
        }

        .lm--checkbox {
          .lm--checkbox-label {
            font-size: 0.8rem;

            &:before {
              flex-basis: 2.5rem;
            }
          }
        }

        .check-node {
          p {
            margin-left: 2rem;
          }
        }
      }

      .label {
        text-align: right;
        width: 45%;
      }

      .data {
        text-align: left;
        width: 55%;
      }
    }
  }

  .terms-node {
    display: flex;
    position: relative;
    font-size: 0.8rem;
    flex-direction: row;

    .terms-control {
      width: 32px;
    }

    .terms-text {
      padding-top: 0.2rem;
    }

    .by-clicking {
      position: relative;
      display: flex;

      // &:before {
      //   content: '';
      //   display: inline-block;
      //   width: 1.5rem;
      //   height: 1.5rem;
      //   margin-right: .5rem;
      // }
    }

    span {
      display: inline !important;
    }

    a {
      display: inline;
    }

    ol {
      // margin: 0.5rem;
      margin: 0;
      padding: 0.5rem 1rem;

      ol {
        list-style-type: lower-alpha;
      }

      li {
        margin-bottom: 0.4rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    margin: 0 auto;
    background-color: #ffffff;
  }

  .signup {
    max-width: 768px;
    margin: 0 auto;
    padding: 7rem 1rem;
    min-height: 100vh;
  }

  .plans_list {
    .plans_list--item {
      padding: 1.5rem 5rem;
      border-bottom: 1px solid rgba(231, 236, 239, 1);

      &:last-child {
        border-bottom: 0;
      }
    }

    .item-title {
      line-height: 1;
    }

    .item-details {
      ul {
        padding-left: 1rem;

        li {
          padding-bottom: 0.875rem;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .signature-nodes {
    display: flex;
    padding-bottom: 1rem;

    .signature-node {
      width: calc(50% - 2rem);
      margin: 0 1rem;
    }
  }

  .signature-node {
    display: inline-block;
    width: 100%;

    .sn-signed {
      height: 100px;
      position: relative;

      .display-value,
      .helper-text {
        bottom: 0;
        position: absolute;
        text-align: center;
        width: 100%;
      }

      .helper-text {
        font-weight: 500;
      }

      .helper-text[aria-disabled="false"] {
        cursor: pointer;
      }

      img {
        display: block;
        margin: auto;
        height: 100px;
      }
    }

    .sn-line {
      display: block;
      width: 100%;
      padding-bottom: 0.5rem;
      border-top: 1px dotted #979797;
    }

    .sn-by {
      font-size: 0.8rem;

      span {
        display: block;
        text-align: center;
      }
    }
  }

  .for-print {
    display: none;
  }

  .additional-documents {
    display: block;

    & > div {
      margin-top: 0.5rem;

      a {
        display: inline-block;
      }

      .upload-input {
        margin-top: 0 !important;
      }
    }
  }

  .oa-preloader {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .notice-box {
    display: block;
    border: 1px solid #ccc;
    color: #d50000;
    padding: 15px;
    margin: 20px auto;
    font-size: 16px;
    background: snow;
  }
}

.required-document-prompt {
  padding-top: 30px;
  padding-bottom: 30px;

  .document-message {
    li {
      margin-bottom: 5px;
    }

    .tooltip-target {
      color: #00b0b2;
    }

    .tooltip_pop {
      background-color: #444;
      color: #ffffff;
    }
  }

  h3 {
    span {
      text-transform: capitalize;
    }
  }
}

.btn-print {
  display: inline-block;
  margin: 1rem;
  margin-bottom: 0.7rem;
}

@media print {
  @page {
    size: auto;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: "geomanist", Arial;
    color: #4f5a60;
    margin: 0;
    padding: 2rem 0;
  }

  .for-print {
    display: block;
  }

  .print-container {
    width: 100%;
    padding: 2rem 0;
  }

  #printable {
    .print-pagebreak {
      page-break-before: always;
    }

    .printable-border {
      border: 1px solid #ced5dc;
      padding: 3rem 1rem 1rem;
    }

    max-width: 588px;
    margin: 0 auto;

    .page-title {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 2.5rem;
    }

    .terms-and-conditions {
      display: block;
      margin-top: 120px;
      text-align: left;

      .extTitle {
        font-size: 18px;
        font-weight: 500;
        margin-top: 16px;
        margin-bottom: 10px;
      }
    }

    .logo {
      display: block;
      text-align: left;
      position: absolute;
      top: 1.5rem;
      left: 0;

      img {
        width: 100px;
        display: inline-block;
      }
    }

    .signature-nodes {
      display: flex;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .signature-node {
        width: calc(50% - 2rem);
        margin: 0 1rem;
      }
    }

    .terms-and-conditions {
      padding: 1rem 0;
    }

    .detail_section {
      .detail_section--content,
      .detail_section--title {
        .label {
          width: 45% !important;
        }

        .data {
          width: 55% !important;
        }
      }

      .detail_section--title {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;

        // width: 33%;
      }

      .detail_section--content {
        // width: 67%;
        padding-bottom: 1rem;

        .label {
          width: 45%;
          font-size: 0.9rem;
        }

        .data {
          width: 55%;
          font-size: 0.9rem;
        }

        .u-grid {
          padding-bottom: 0.5rem;
        }

        span {
          display: block;
        }

        .thumb {
          display: inline-block;
          width: 64px;
          height: 49px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 0.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .detail_section--checks {
        .u-grid {
          padding-bottom: 0;

          .u-cell {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .lm--formItem-label {
          display: none;
        }

        .lm--checkbox {
          .lm--checkbox-label {
            font-size: 0.8rem;

            &:before {
              flex-basis: 2.5rem;
            }
          }
        }
      }

      .u-cell {
        width: 67%;

        &.label {
          text-align: right;
          width: 33%;
        }

        &.data {
          text-align: left;
        }
      }
    }
  }

  .signature-node {
    display: inline-block;
    width: 100%;
    padding: 5rem 0 0;

    .sn-line {
      display: block;
      width: 100%;
      padding-bottom: 0.5rem;
      border-top: 1px dotted #979797;
    }

    .sn-by {
      font-size: 0.8rem;

      span {
        display: block;
        text-align: center;
      }
    }
  }
}
