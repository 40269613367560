@import "~@lumi/css-variables/dist/index.scss";

.cui-choices {
  display: flex;
  max-width: 500px;
  min-width: 160px;

  &.is-crosswise {
    flex-direction: row;
    align-items: flex-start;

    .cui-choice {
      margin-left: 0.5rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.is-lengthwise {
    flex-direction: column;

    .cui-choice {
      margin-left: 0;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
