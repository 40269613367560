.upload-input {
  position: relative;
  display: flex;

  &.processing {
    &:hover {
      .mock_file--btn {
        background-color: rgba( 231,236,239,1) !important;
      }
    }
  }

  &.single {
    &.--uploaded {
      // height: calc(40px + 1rem);
      padding-top: 0.2rem;
      .upload_field {
        // padding-top: 0.4rem;
        .upload_field-results {
          padding: 0;
          ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
              border: 0;
              min-height: auto;
              padding: 0;
              span {
                font-size: 0.9rem;
              }
              button {
                margin-top: 0;
                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  
  &.upload-img {
    display: inline-flex;
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 5px;
    border: 1px dotted rgba( 206, 213, 220,1);
    line-height: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.--uploaded {
      &:before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;
        background-color: rgba(0,0,0,0.4);
        opacity: 0;
      }
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }
    &:hover {
      border-color: rgba(0,0,0,0.5);
    }
    .upload_field {
      font-size: 0.8rem;
      line-height: 1.2;
    }
  }

  &.upload-doc {
    .mock_file {
      display: block;
      position: relative;
      cursor: pointer;
      &:hover {
        .mock_file--btn {
          background-color: rgba( 208,217,222,1);
        }
      }
      .mock_file--btn {
        display: inline-block;
        padding: 0.5rem 1.25rem;
        background-color: rgba( 231,236,239,1);
        font-size: 0.875rem;
        font-weight: 500;
        color: #000;
        vertical-align: middle;
        border-radius: 5px;
        min-width: 113px;
        text-align: center;
        position: relative;
        min-height: 36px;
        .app-icon {
          position: absolute;
          display: inline-block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .mock_file--msg {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(12);
        color: rgba( 129,138,145,1);
        margin-left: 0.5rem;
      }
    }
  }
  input {
    &[type=file] {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      cursor: pointer;
    }
    &[type=text] {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  .upload-label {
    display: inline-flex;
    width: calc(100% - (240px + 1.438rem));
    padding-right: 1.438rem;
        min-height: 36px;
        margin-bottom: 0 !important;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
  }
  .upload_field {
    display: inline-block;
    width: 100%;
    .upload_field--thumb {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0%;
      z-index: 1;
      height: 100%;
      width: 100%;
      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    .upload_field-results {
      padding: rem(20) 0;
      ul {
        li {
          display: flex;
          list-style: none;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0.5rem 0;
          min-height: 56px;
          border-bottom: 1px solid rgba( 231,236,239,1);
          &.deleting {
            opacity: 0.8;
            button {
              color: #333333;
            }
          }
          i {
            display: inline-block;
            line-height: 0;
            padding: rem(5);
            vertical-align: middle;
          }
          span {
            margin-left: 0.5rem;
            font-size: 1rem;
            flex-grow: 1;
            vertical-align: middle;
          }
          button {
            display: inline-block;
            margin-top: rem(3);
            outline: none !important;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.upload-doc-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  margin-right: 0.4rem;
}
.upload-doc-type {
  display: inline-block !important;
  vertical-align: middle;
  padding: 0.2rem;
  background-color: #9ba6aa;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.7rem;
}
