.tooltip {
  display: inline-block;
  .tooltip-target {
    display: flex;
    padding: 0;
    margin: 0 0 0 0.5rem;
    justify-content: center;
    align-items: center;
    color: #818A91;
    outline: none;
    .app-icon {
      margin-right: 0.25rem;
    }
  }  
}
.tooltip_pop {
  min-width: 50px;
  min-height: 30px;
  max-width: 250px;
  background-color: #F4F4F4;
}
