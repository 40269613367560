.app-menu {
  padding: 0;
  min-height: initial !important;
  min-width: 300px !important;
  border-radius: 5px;
  &.listaction-option {
    min-width: initial;
  }
  & > div {
    width: 100%;
    background-color: inherit;
    z-index: 2;
    max-height: 290px;
    overflow-y: scroll;
  }
  ul {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e7ecef;
    list-style: none;
    padding: 0.5rem 0;
    margin-bottom: 0;

    &:only-child{
      border-top: 0;
    }

    li {
      display: block;
      padding-left: 0;
      &:hover {
        &:not(.disabled) {
          a, button {
            background-color: #f3f4f3;
          }
        }
      }

      &.menu-subhead {
        font-size: 0.8rem;
        padding: 0.5em 1.2em;
      }

      &.disabled {
        a, button {
          cursor: initial;
          span {
            color: #bec8ce;
          }
        }
      }

      &.active {
        border: 1px solid #00b0b2;
        a, button {
          font-weight: bold;
          color: #00b0b2;
        }
      }
     
      a, button {
        display: block;
        width: 100%;
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
        color: inherit;
        text-decoration: none;
        outline: none !important;
        background-color: inherit;
       
        i,span {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
        }
        i {
          display: inline-block;
          margin-right: 1em;
          line-height: 0;
          
          width: 20px;
          height: 20px;

          svg, img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .name {
        display: inline-block;
      }
    }
  }

  .menu-title {
    font-size: 1.2rem;
    display: block;
    padding: 1em;
    text-align: center;
  }
}
