.signup--confirmation,
.signup--print,
.signup--upload {
  .qr-upload {
    h4 {
      margin-bottom: 0.5rem;
    }

    .qr-container {
      text-align: center;
      position: relative;
    }

    canvas {
      margin: 1rem 0;
    }

    .doc-group {
      margin-bottom: 0.5rem;
    }

    .doc-type {
      display: inline-block;
      margin-right: 2rem;
    }

    .doc-value {
      display: inline-block;
    }

    .qr-wrapper.error {
      opacity: 0.1;
    }

    .qr-error {
      position: absolute;
      top: 40%;
      right: 40%;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .refresh-qr {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
