.form-myinfo {
  .lm--formItem-label {
    min-height: 36px !important;
  }
  .lm--formItem-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
  }
  .myinfo-logo {
    width: 81px;
    line-height: 0;
    margin-right: 1rem;
  }
  .lm--button {
    min-height: 36px;
    padding-top: .2625rem;
    padding-bottom: .2625rem;
    font-size: 0.8rem;
    &.outline {
      background-color: transparent;
      border: 1px solid #00b0b2;
      span {
        color: #00b0b2;
      }
    }
    .myinfo-logo { 
      display: inline-block;
      width: 50px;
      vertical-align: middle;
      margin: 0;
    }
  }
  .tooltip {
    margin-left: 1rem;
  }
}